
import $ from "jquery"; 
import { getCountryFromTimezone } from "./lib/timezone/timezone";

const modal = document.querySelector('.popup-message-modal')
import {  getCookie } from "./util.js"

// Set a flag in local storage saying region selector hidden, so we dont prompt the user again
$(modal).on('hide.bs.modal', () => {
    window.localStorage.popupMessageModalHidden = "true"
})


// Has the user chosen a country?
let currentCountry = (getCookie("barnabasfund-org-country") || "").toLowerCase() || getCountryFromTimezone();

const showCountries = ["gb"]
// Open the country select modal for users outside a recognized country
if (showCountries.includes(currentCountry) && window.localStorage.popupMessageModalHidden !== "true") {
    $(modal).modal('show')
}
